


export const theme={
    colors:{
        accent: '#51B9D9',
        form:'#3d8cc7',
        primaryText:'#3d8cc7',
        secondaryText:'#FFFFFF',
        sectionTitle:'#52525e'
    },


    media:{
        tablet:'screen and (max-width: 830px)',
        mobile:'screen and (max-width: 576px)',

    }
}